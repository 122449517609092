<template>
    <Layout>
        <div class="pageSection">
            <div class="pageContent">
                <div class="shop">
                    <p class="h1">Магазин программ</p>

                    <div
                        v-if="userTotalPrograms && userTotalPrograms.length"
                    >
                        <p class="pageSubtitle colorFiord">Купленные</p>
                        <div class="shop__content">
                            <div
                                :class="
                                    viewAll
                                        ? `shop__myContentItem shop__myContentItem--hidden`
                                        : `shop__myContentItem`
                                "
                                v-for="item in userTotalPrograms"
                                :key="item.value"
                            >
                                <ShopCard
                                    :cardIcon="item.isOldProduct ? $oldClientUrl.slice(0, -1) + item.medium_image : $oldClientTestUrl.slice(0, -1) + item.medium_image"
                                    :cardTitle="item.name"
                                    :cardDate="item.duration"
                                    :cardRoute="
                                        item.slug === 'trejding_ot_a_do_ya_3_5'
                                            ? item.slug + '/dashboard'
                                            : 'https://edu.gerchik.ru/programs/' +
                                              item.slug
                                    "
                                    :isExternalLink="
                                        item.slug === 'trejding_ot_a_do_ya_3_5'
                                            ? false
                                            : true
                                    "
                                    :daysTillGraduation="item.to_the_end"
                                    buttonTitle="Продолжить"
                                />
                            </div>
                            <div
                                v-if="userTotalPrograms.length > 4"
                                :class="viewAll ? `shop__viewAll` : `hidden`"
                                @click="viewAll = !viewAll"
                            >
                                <p class="h3 colorGullGray">
                                    Показать еще
                                    {{ userTotalPrograms.length - 3 }}
                                    <br />
                                    купленные программы
                                </p>
                            </div>
                        </div>
                    </div>

                    <div v-if="allProducts.length">
                        <p class="pageSubtitle colorFiord">Все программы</p>
                        <div class="shop__content">
                            <div class="shop__contentItem">
                                <ShopCard
                                    :cardIcon="ThumbProContent"
                                    cardTitle="Pro контент"
                                    cardDate="365 дней"
                                    cardRoute="https://gerchik.ru/pro-content"
                                    :isExternalLink="true"
                                    buttonTitle="Купить"
                                />
                            </div>
                            <div class="shop__contentItem">
                                <ShopCard
                                    :cardIcon="ThumbGP_3_5"
                                    cardTitle="Волки с Уолл Стрит 3.5"
                                    cardDate="365 дней"
                                    cardRoute="https://gerchik.ru/trainings/wolfs"
                                    :isExternalLink="true"
                                    buttonTitle="Купить"
                                />
                            </div>
                            <div class="shop__contentItem">
                                <ShopCard
                                    :cardIcon="ThumbRealtime"
                                    cardTitle="Bootcamp"
                                    cardDate=""
                                    cardRoute="https://gerchik.ru/bootcamp/"
                                    :isExternalLink="true"
                                    buttonTitle="Купить"
                                />
                            </div>
                            <div class="shop__contentItem">
                                <ShopCard
                                    :cardIcon="ThumbRealtimeRecord"
                                    cardTitle="Bootcamp в записи"
                                    cardDate="4 недели"
                                    cardRoute="https://gerchik.ru/trainings/bootcamprecord"
                                    :isExternalLink="true"
                                    buttonTitle="Купить"
                                />
                            </div>
                            <div class="shop__contentItem">
                                <ShopCard
                                    :cardIcon="ThumbProstojStart"
                                    cardTitle="Трейдинг: Простой старт за 10 дней"
                                    cardDate="1 неделя"
                                    cardRoute="https://gerchik.ru/trainings/trading-start-za-10-dnei"
                                    :isExternalLink="true"
                                    buttonTitle="Купить"
                                />
                            </div>
                            <div
                                class="shop__contentItem"
                                v-for="item in allProducts.filter(
                                    (item) => item.button === 'buy'
                                )"
                                :key="item.slug"
                            >
                                <ShopCard
                                    :cardIcon="$oldClientTestUrl.slice(0, -1) + item.medium_image"
                                    :cardTitle="item.name"
                                    :cardDate="item.duration"
                                    :cardRoute="item.landingUrl"
                                    :isExternalLink="true"
                                    buttonTitle="Купить"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Layout";
import ShopCard from "@/views/components/ShopCard/ShopCard";

import ThumbProContent from "../../../assets/img/pages/shop/thumb8.jpg";
import ThumbGP_3_5 from "../../../assets/img/pages/shop/thumb13.jpg";
import ThumbRealtime from "../../../assets/img/pages/shop/realtime.png";
import ThumbRealtimeRecord from "../../../assets/img/pages/shop/realtime-record.png";
import ThumbProstojStart from "../../../assets/img/pages/shop/prostoj-start.png";

export default {
    name: "shop",

    components: {
        Layout,
        ShopCard,
    },

    computed: {
        userEmail: function() {
            return this.$store.getters.getAuth && this.$store.getters.getEmail ? this.$store.getters.getEmail : "";
        },

        userPrograms: function() {
            return this.$store.getters.getUserPrograms &&
                this.$store.getters.getUserPrograms.length ?
                this.$store.getters.getUserPrograms :
                [];
        },

        userOldPrograms: function() {
            return this.$store.getters.getUserOldPrograms &&
                this.$store.getters.getUserOldPrograms.length ?
                this.$store.getters.getUserOldPrograms.filter(program => program.isOldProduct = true) :
                [];
        },

        userTotalPrograms: function() {
            return [...this.userPrograms, ...this.userOldPrograms];
        },
    },

    mounted() {
        if (window.location.pathname === "/") {
            this.$store.dispatch("clearMember");
        }
    },

    async beforeMount() {
        const getProductsDataResult = await this.axios.get("/store");
        if (
            getProductsDataResult &&
            getProductsDataResult.data &&
            getProductsDataResult.data.result
        ) {
            this.allProducts = getProductsDataResult.data.courses;
        }

        this.updateUserProgramsStore();
    },

    data() {
        return {
            title: "Магазин программ",

            ThumbProContent: ThumbProContent,
            ThumbGP_3_5: ThumbGP_3_5,
            ThumbRealtime: ThumbRealtime,
            ThumbRealtimeRecord: ThumbRealtimeRecord,
            ThumbProstojStart: ThumbProstojStart,

            viewAll: true,
            allProducts: [],
        };
    },

    methods: {
        async updateUserProgramsStore() {
            if (this.userEmail) {
                const getUserProgramsDataResult = await this.axios.get("/programs/products?email=" + this.userEmail);

                if (
                    getUserProgramsDataResult &&
                    getUserProgramsDataResult.data &&
                    getUserProgramsDataResult.data.result
                ) {
                    await this.$store.dispatch("setUserPrograms", {
                        programs: getUserProgramsDataResult.data.products,
                    });
                    await this.$store.dispatch('setUserOldPrograms', {
                        oldPrograms: getUserProgramsDataResult.data.oldProducts
                    });
                } else {
                    console.error("Error with user courses load");
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "shop.scss";
</style>
