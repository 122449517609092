<template>
    <div class="shopCard">
        <img class="shopCard__img" v-if="cardIcon" v-bind:src="cardIcon" />
        <div class="shopCard__content">
            <div class="shopCard__contentItem">
                <p class="h3" v-if="cardTitle">
                    {{ cardTitle }}
                </p>
                <p class="shopCard__textDate" v-if="cardDate">
                    <img src="@/assets/img/common/iconCalendar.svg" class="shopCard__icon" />
                    {{ cardDate }}
                </p>
                <div
                    class="colorGray shopCard__description"
                    v-if="
                        cardDescriptionStart &&
                            cardDescriptionNumber &&
                            cardDescriptionEnd
                    "
                >
                    <img
                        v-if="iconDescription"
                        v-bind:src="iconDescription"
                        class="shopCard__icon"
                    />
                    <p>
                        {{ cardDescriptionStart }}

                        <strong class="colorBlack">
                            {{ cardDescriptionNumber }}
                        </strong>

                        {{ cardDescriptionEnd }}
                    </p>
                </div>

                <div
                    class="colorGray shopCard__description"
                    v-if="cardDurationStart && cardDurationNumber"
                >
                    <img
                        v-if="iconDuration"
                        v-bind:src="iconDuration"
                        class="shopCard__icon"
                    />
                    <p>
                        {{ cardDurationStart }}

                        <strong class="colorBlack">
                            {{ cardDurationNumber }}
                        </strong>
                    </p>
                </div>
                <div
                    class="colorGray shopCard__description"
                    v-if="cardLessonsViewed && cardLessonsViewedNumber"
                >
                    <img
                        v-if="iconLessonsViewed"
                        v-bind:src="iconLessonsViewed"
                        class="shopCard__icon"
                    />
                    <p>
                        {{ cardLessonsViewed }}

                        <strong class="colorBlack">
                            {{ cardLessonsViewedNumber }}
                        </strong>
                    </p>
                </div>
                <div
                    class="colorGray shopCard__description"
                    v-if="cardEndCourse && cardEndCourseNumber"
                >
                    <img
                        v-if="iconEndCourse"
                        v-bind:src="iconEndCourse"
                        class="shopCard__icon"
                    />
                    <p>
                        {{ cardEndCourse }}

                        <strong class="colorBlack">
                            {{ cardEndCourseNumber }}
                        </strong>
                    </p>
                </div>
            </div>
            <div class="shopCard__footer">
                <Button
                    v-if="cardRoute && buttonTitle"
                    class="shopCard__button"
                    :isLink="true"
                    :isExternalLink="isExternalLink"
                    :link="cardRoute"
                    :title="buttonTitle"
                    :onClick="onClick"
                />
                <div
                    v-if="daysTillGraduation"
                    class="shopCard__tillGraduation"
                >
                    <p class="colorGray h5">До конца обучения:</p>
                    <p>
                        <b>{{daysTillGraduation}}</b>
                        <span v-if="daysTillGraduation === 1"> день</span>
                        <span v-else-if="daysTillGraduation > 1 && daysTillGraduation < 5"> дня</span>
                        <span v-else> дней</span>
                    </p>
                </div>
                <div
                    v-else-if="daysTillGraduation === 0"
                    class="shopCard__tillGraduation"
                >
                    <p class="colorGreen"><b>Обучение завершено</b></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
export default {
    name: "shopCard",

    components: {
        Button,
    },

    props: {
        cardTitle: String,
        cardDate: String,
        cardDescriptionStart: String,
        cardDescriptionNumber: String,
        cardDescriptionEnd: String,
        iconDescription: String,
        cardIcon: String,
        cardRoute: String,
        buttonTitle: String,
        isExternalLink: Boolean,
        daysTillGraduation: Number,

        iconDuration: String,
        cardDurationStart: String,
        cardDurationNumber: String,
        iconLessonsViewed: String,
        cardLessonsViewed: String,
        cardLessonsViewedNumber: String,
        iconEndCourse: String,
        cardEndCourse: String,
        cardEndCourseNumber: String,
        onClick: Function
    },
};
</script>

<style lang="scss">
@import "./shopCard.scss";
</style>
